.errorPage {
  display: flex;
  height: 90%;
  margin-top: 0%;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: rgb(124, 128, 143);
  flex-direction: column;
}
.errorImage {
  width: 60%;
}
.errorInfo {
  margin-top: -8%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(73, 96, 189);
  flex-direction: column;
  text-align: center;
}
