@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab&display=swap");

.navigator {
  font-family: "Zilla Slab", serif;
  font-size: large;
}

.menu-button {
  position: absolute;
  top: 50px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 3rem;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.XbtnPosition {
  position: fixed;
  right: 40px;
}

.bar {
  width: 100%;
  height: 4px;
  background: #000000;
  transition: 0.4s ease-in-out;
}

/* Nine animations for the bars */
.menu-button.open .bar:nth-child(1) {
  animation: rotate1 0.4s ease-in-out forwards;
  background: red;
}

.menu-button.open .bar:nth-child(2) {
  animation: fadeOut 0.4s ease-in-out forwards;
  background: red;
}

.menu-button.open .bar:nth-child(3) {
  animation: rotate2 0.4s ease-in-out forwards;
  background: red;
}

@keyframes rotate1 {
  100% {
    transform: rotate(45deg) translateY(10px);
  }
}

@keyframes rotate2 {
  100% {
    transform: rotate(-45deg) translateY(-10px);
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}

.menu-container {
  z-index: 99;
  position: fixed;
  top: 0;
  left: -400px;
  height: 100vh;
  width: 400px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: left 0.5s ease-in-out;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: #0779c5;
}

.menu-container.open {
  left: 0;
}
ul,
ol {
  padding-left: 0;
}
.menu-list {
  list-style: none;
  width: 100%;
}

.menu-list li {
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.3s;

  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.menu-list li:hover {
  background-color: #272acf;
  color: white;
  width: 100%;
}

.menu-image {
  width: 200px;
  height: auto;
}
