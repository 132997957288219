.PrivacyPage {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.privacyFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.privacy {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 200px;
  margin-bottom: 400px;
}

.privacy h1 {
  text-align: center;
  color: #00449e;
}

.privacy h2 {
  color: #0056b3;
}

.privacy p {
  margin-bottom: 16px;
}
