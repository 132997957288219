/* Background gradient animation */
@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Lighting effect overlay */
.FT-lighting-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(32, 54, 252, 0.5),
    rgba(9, 33, 112, 0.4),
    rgba(16, 124, 248, 0.5)
  );
  background-size: 200% 200%;
  animation: gradientBackground 10s ease infinite;
  z-index: 0;
}

/* FreeTrial container styling */
.FreeTrial {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../Images/FreeTrial/freeTrial.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: animation-top 1s ease-out both;
  position: relative;
  overflow-y: auto;
}

/* Animation for container entry */
@keyframes animation-top {
  0% {
    transform: scale(0.5) translateY(-80%);
    transform-origin: 50% 16%;
  }
  100% {
    transform: scale(1) translateY(0);
    transform-origin: top;
  }
}

/* Steps container */
.StepsToSchedule {
  z-index: 5;
  padding-top: 150px;
  padding-bottom: 100px;
}

/* Form styling */
.FreeTrialForm {
  background-color: #fff;
  width: 90%;
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  border-radius: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #444;
}

/* Form title & paragraph */
.FTFormTitle {
  font-size: 42px;
  margin-bottom: 1px;
}
.FTFormParagraph {
  font-size: 18px;
  margin-bottom: 50px;
}

/* Grouping two inputs side by side */
.FTGroupOf2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}
.FTrightSide,
.FTleftSide {
  width: 48%;
  display: flex;
  flex-direction: column;
}

/* Input, textarea and select styling */
.FreeTrialForm input,
.FreeTrialForm textarea,
.FreeTrialForm select {
  padding: 10px;
  border: none;
  box-shadow: 0px 0px 4px 0px #000;
  border-radius: 0.5rem;
  margin-top: 5px;
  width: 100%;
}

/* Form label styling */
.formLabel {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 100%;
}

/* Calendar container and calendar style */
.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.react-calendar {
  border-radius: 1rem;
  padding: 15px;
  font-size: 20px;
}

/* Calendar buttons styling */
.CalendarButtons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/* Transition animations */
.transition-enter {
  opacity: 0;
  transform: translateX(100%);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.transition-exit {
  opacity: 1;
  transform: translateX(0);
}
.transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

/* Mobile responsive adjustments */
@media (max-width: 500px) {
  .FreeTrial {
    justify-content: flex-start;
    padding-top: 20px;
    /* min-height: auto; */
  }
  .FreeTrialForm {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
  }
  .FTGroupOf2 {
    flex-direction: column;
  }
  .FTrightSide,
  .FTleftSide {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }
  .FTFormTitle {
    font-size: 32px;
  }
  .FTFormParagraph {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .CalendarButtons {
    flex-direction: row;
    align-items: center;
  }
}
