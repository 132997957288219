.Navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 99;
}

.logo {
  font-size: 45px;
  font-style: italic;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  padding: 5px 15px;
  border-radius: 1rem;
  background: linear-gradient(90deg, #ffffff, #cccccc);
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  height: 70px;
  width: 70px;
}
.sideNavLogo {
  font-size: 45px;
  font-style: italic;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  padding: 5px 15px;
  border-radius: 1rem;
  background: linear-gradient(90deg, #ffffff, #cccccc);
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sideNavLogo img {
  height: 70px;
  width: 70px;
}

.NavigationTitles {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 22px;
  opacity: 1;
}
.fullNavBar {
  display: block;
  opacity: 1;
}
.side-nav {
  display: none;
  opacity: 0;
}
.NavigationTitles li {
  margin-left: 20px;
}

.NavigationTitles a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
  border-radius: 2.5rem;

  transition: color 0.3s ease, background-color 0.3s ease;
}

.NavigationTitles a:hover,
.NavigationTitles .active a {
  color: #000000;
  background-color: white;
}

.ctaButton {
  list-style: none;
  margin-left: 20px;
}

.ctaButton a {
  display: inline-block;
  background-color: #ff6b6b;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ctaButton a:hover {
  background-color: #f06595;
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.ctaButton a:active {
  background-color: #e05585;
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.ctaButton a:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 107, 107, 0.5);
}

@media (max-width: 1350px) {
  .side-nav {
    display: block;
    opacity: 1;
  }
  .fullNavBar {
    display: none;
    opacity: 0;
  }
  .NavigationTitles {
    flex-direction: column;
  }
  .NavigationTitles li {
    margin-left: 0;
    margin-top: 30px;
  }
  .logo {
    font-size: 35px;
  }
}
@media (max-width: 450px) {
  .logo {
    font-size: 25px;
    padding: 5px 10px;
  }
}
