.footer {
  background-color: #0328fa;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  display: inline;
  margin-left: 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: underline;
}

.footer-links a:hover {
  text-decoration: underline;
}

@media (max-width: 700px) {
  .footer-content {
    flex-direction: column;
  }
}
