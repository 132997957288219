body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  background-color: #3b6df7;
  color: #ffffff;
  font-size: 17px;
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
  padding: 12px 24px;
  border-radius: 2.5rem;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.btn:hover {
  background-color: #3b6df79d;
}

.middleLoading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.loadingBar {
  width: 10px;
  height: 70px;
  background: none;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /* box-shadow: 5px 10px 20px inset rgb(23, 23, 255); */
  animation: loader 1.2s linear infinite;
}
.loadingBar1 {
  animation-delay: 0.1s;
}
.loadingBar2 {
  animation-delay: 0.2s;
}
.loadingBar3 {
  animation-delay: 0.3s;
}
.loadingBar4 {
  animation-delay: 0.4s;
}
.loadingBar5 {
  animation-delay: 0.5s;
}
.loadingBar6 {
  animation-delay: 0.6s;
}
.loadingBar7 {
  animation-delay: 0.7s;
}
.loadingBar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
    background: none;
  }
  50% {
    transform: scaleY(1);
    background: blue;
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}
