/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  color: #333;
  line-height: 1.6;
  background-color: #f8f9fa;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn {
  display: inline-block;
  padding: 14px 32px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.btn-primary {
  background-color: #4361ee;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #3046c9;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.15);
}

.btn-secondary {
  background-color: #f8f9fa;
  color: #4361ee;
  border: 2px solid #4361ee;
}

.btn-secondary:hover {
  background-color: #f0f4ff;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.1);
}

section {
  padding: 80px 0;
}

.section-title {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #1a1a1a;
}

.section-description {
  font-size: 18px;
  margin-bottom: 40px;
  color: #555;
  max-width: 800px;
}

/* Navigation */
.navbar {
  padding: 20px 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 1000;
}
.Schedule-call-mobile {
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-size: 24px;
  font-weight: 700;
  color: #4361ee;
  text-decoration: none;
}
.HomePageLogo {
  font-size: 45px;
  font-style: italic;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  padding: 5px 15px;
  border-radius: 1rem;
  background: linear-gradient(90deg, #ffffff, #cccccc);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.HomePageLogo img {
  height: 70px;
  width: 70px;
}

.navbar-links {
  display: flex;
  align-items: center;
  list-style: none;
}

.navbar-link {
  margin-left: 30px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #4361ee;
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, #4361ee 0%, #3a0ca3 100%);
  color: white;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  /* background: url("/api/placeholder/600/600") no-repeat center center; */
  background-size: cover;
  opacity: 0.15;
}

.hero-content {
  max-width: 600px;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 24px;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 40px;
  opacity: 0.9;
}

/* Lead Capture Section */
.lead-capture {
  background-color: white;
  padding: 60px 0;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.lead-capture-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #1a1a1a;
}

.lead-capture-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px 0;
  gap: 30px;
}

.feature-item {
  flex-basis: calc(50% - 40px);
  max-width: 400px;
  text-align: left;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.feature-item strong {
  color: #4361ee;
  font-weight: 700;
}

.lead-buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Carousel Section */
.carousel {
  background-color: #f8f9fa;
  padding: 80px 0;
  overflow: hidden;
}

.carousel-container {
  position: relative;
}

.carousel-title {
  text-align: center;
  margin-bottom: 60px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.carousel-image {
  width: 300px;
  height: 300px;
  background-color: #e0e0e0;
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;
}

.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-item-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #1a1a1a;
}

.carousel-item-description {
  font-size: 16px;
  color: #555;
  max-width: 600px;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 15px;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-dot.active {
  background-color: #4361ee;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
  font-size: 24px;
  color: #333;
  transition: all 0.3s ease;
}

.carousel-arrow:hover {
  background-color: #4361ee;
  color: white;
}

.carousel-arrow-left {
  left: 20px;
}

.carousel-arrow-right {
  right: 20px;
}

/* Features Section */
.features {
  background-color: white;
  padding: 100px 0;
}

.features-title {
  text-align: center;
  margin-bottom: 60px;
}

.features-container {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.feature-row {
  display: flex;
  align-items: center;
  gap: 60px;
}

.feature-row:nth-child(even) {
  flex-direction: row-reverse;
}

.feature-content {
  flex: 1;
}

.feature-image {
  flex: 1;
  height: 400px;
  background-color: #f0f0f0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.feature-description {
  font-size: 16px;
  color: #555;
  line-height: 1.7;
}

/* Support Section */
.support {
  background-color: #f8f9fa;
  padding: 100px 0;
}

.support-title {
  text-align: center;
  margin-bottom: 20px;
}

.support-description {
  text-align: center;
  margin-bottom: 60px;
}

.support-items {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.support-item {
  flex: 1;
  min-width: 300px;
  max-width: 350px;
  background-color: white;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.support-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.support-item-icon {
  font-size: 40px;
  color: #4361ee;
  margin-bottom: 20px;
}

.support-item-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #1a1a1a;
}

.support-item-description {
  font-size: 16px;
  color: #555;
  line-height: 1.7;
}

/* Contact Form Section */
.contact {
  background-color: white;
  padding: 100px 0;
}

.contact-container {
  display: flex;
  gap: 60px;
  align-items: center;
}

.contact-info {
  flex: 1;
}

.contact-title {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 20px;
  color: #1a1a1a;
}

.contact-description {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

.contact-details {
  margin-top: 40px;
}

.contact-detail {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-detail-icon {
  width: 40px;
  height: 40px;
  background-color: #f0f4ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #4361ee;
}

.contact-detail-text {
  font-size: 16px;
  color: #555;
}

.contact-form {
  flex: 1;
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.2);
}

.form-control::placeholder {
  color: #aaa;
}

textarea.form-control {
  resize: vertical;
  min-height: 120px;
}

.submit-btn {
  width: 100%;
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background-color: #3046c9;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(67, 97, 238, 0.15);
}

/* Footer */
.footer {
  background-color: #1a1a1a;
  color: white;
  padding: 60px 0 30px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  margin-bottom: 60px;
}

.footer-column {
  flex: 1;
  min-width: 250px;
}

.footer-logo {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
  display: block;
}

.footer-description {
  font-size: 14px;
  color: #aaa;
  margin-bottom: 20px;
  line-height: 1.7;
}

.footer-social {
  display: flex;
  gap: 15px;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: #4361ee;
}

.footer-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-links {
  list-style: none;
}

.footer-link {
  margin-bottom: 10px;
}

.footer-link a {
  color: #aaa;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link a:hover {
  color: white;
}

.copyright {
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: #aaa;
}

/* Mobile Responsive */
@media (max-width: 992px) {
  .HomePageLogo {
    font-size: 35px;
  }
  .feature-row {
    flex-direction: column;
    gap: 40px;
  }

  .feature-row:nth-child(even) {
    flex-direction: column;
  }

  .contact-container {
    flex-direction: column;
  }

  .lead-capture-features {
    flex-direction: column;
  }

  .feature-item {
    flex-basis: 100%;
  }

  .carousel-arrow {
    display: none;
  }

  .section-title,
  .hero-title {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  /* Navigation */
  .navbar {
    padding: 10px 0;
  }
  .navbar-container {
    display: flex;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
  }
  .HomePageLogo {
    font-size: 26px;
    padding: 2px 5px;
  }
  .HomePageLogo img {
    height: 60px;
    width: 60px;
  }
  .Schedule-call-mobile {
    display: block;
    background-color: #007bff;
    color: #fff;
    padding: 12px;
    border-radius: 1rem;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  .Schedule-call-mobile:hover {
    background-color: #67a3e4;
  }
  .navbar-links {
    display: none;
  }

  .lead-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .support-items {
    flex-direction: column;
    align-items: center;
  }

  .support-item {
    width: 100%;
  }
  .contact-form {
    width: 100%;
    padding: 10px;
  }
}
