.terms {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 200px;
}

.terms h1 {
  text-align: center;
  color: #00449e;
}

.terms h2 {
  color: #0056b3;
}

.terms p,
.terms ul {
  margin-bottom: 16px;
}

.terms ul {
  margin-left: 20px;
  list-style-type: square;
}

.terms li {
  margin-bottom: 10px;
}
