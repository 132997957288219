/* ScrollToTop.css */
.scroll-to-top button {
  position: fixed;
  bottom: 20px; /* Distance from bottom */
  right: 20px; /* Distance from right */
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height */
  border-radius: 50%; /* Circular button */
  border: none;
  background-color: #024194; /* Dark background for contrast */
  color: white;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  z-index: 1000;
}

.scroll-to-top button:hover {
  background-color: #0258f8; /* Slightly lighter on hover */
  transform: scale(1.1); /* Slight scale to indicate interactivity */
}
